export default function DarkThemeIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8' viewBox='0 0 24 24'>
      <path
        fill='currentColor'
        d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10Zm0-1.5v-17a8.5 8.5 0 0 1 0 17Z'
      />
    </svg>
  )
}
