export default function SearchIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      className='w-4 h-4 mr-2'
    >
      <path d="M19.27 18.9c.43-.69.68-1.52.68-2.4 0-2.5-2-4.5-4.49-4.5s-4.5 2-4.5 4.5 2 4.5 4.5 4.5c.87 0 1.69-.25 2.38-.68l3.12 3.07L22.35 22l-3.08-3.1m-3.81.1c-1.39 0-2.5-1.12-2.5-2.5s1.11-2.5 2.5-2.5 2.49 1.12 2.49 2.5-1.11 2.5-2.49 2.5M22 14h-.55c-.33-.81-.83-1.53-1.45-2.14V10h2v4M20 4h-3V2h3a2 2 0 012 2v3h-2V4m-6 0h-4V2h4v2M4 2h3v2H4v3H2V4a2 2 0 012-2m8 20h-2v-2c.5.82 1.2 1.5 2 2m-8-2h3v2H4a2 2 0 01-2-2v-3h2v3m0-6H2v-4h2v4z" />
    </svg>
  );
}